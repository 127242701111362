






























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Option } from '@/components/base/BaseSelect.vue';
import {
  DevicesAction,
  DevicesActionType,
  DevicesGetter,
  DevicesGetterType,
  DevicesState
} from '@/store/modules/devices/types';
import {
  hasCoordinates,
  isControlCabinetTelemetry,
  isGateway,
  isLamp,
  isSensor
} from '@/utils/type_check';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';
import { Models } from '@mtap-smartcity/api';
import { sort } from '@/utils/sort';
import { AnalyticsTabs, AnalyticsTabsTypes } from '@/constants/main_card_inner_tabs';
import { AppAction, AppActionType, AppState } from '@/store/modules/app/types';
import {
  TelemetryAction, TelemetryActionType, TelemetryGetter, TelemetryGetterType, TelemetryState
} from '@/store/modules/telemetry/types';
import { CircuitsGetter, CircuitsGetterType, CircuitsState } from '@/store/modules/circuits/types';
import { GroupsGetter, GroupsGetterType, GroupsState } from '@/store/modules/groups/types';

const app = namespace('app');
const devices = namespace('devices');
const circuits = namespace('circuits');
const groups = namespace('groups');
const telemetry = namespace('telemetry');

export enum ControllerTypes {
  ve = 've',
  iqrf = 'iqrf',
  iothub = 'iothub',
  GATEWAY_CONTROL_CABINET_1_V1 = 'GATEWAY_CONTROL_CABINET_1_V1',
  GATEWAY_MTAP_1_V1 = 'GATEWAY_MTAP_1_V1',
  GATEWAY_MTAP_7_V1 = 'GATEWAY_MTAP_7_V1',
  GATEWAY_VE_1_V1 = 'GATEWAY_VE_1_V1',
  LAMP_CONTROL_CABINET_1_V1 = 'LAMP_CONTROL_CABINET_1_V1',
  LAMP_MTAP_1_V1 = 'LAMP_MTAP_1_V1',
  LAMP_MTAP_2_V1 = 'LAMP_MTAP_2_V1',
  LAMP_MTAP_3_V1 = 'LAMP_MTAP_3_V1',
  LAMP_MTAP_4_V1 = 'LAMP_MTAP_4_V1',
  LAMP_MTAP_5_V1 = 'LAMP_MTAP_5_V1',
  LAMP_MTAP_6_V1 = 'LAMP_MTAP_6_V1',
  LAMP_MTAP_7_V1 = 'LAMP_MTAP_7_V1',
  LAMP_VE_1_V1 = 'LAMP_VE_1_V1',
  LAMP_VE_2_V1 = 'LAMP_VE_2_V1',
  LAMP_VE_3_V1 = 'LAMP_VE_3_V1',
  SENSOR_MTAP_1_V1 = 'SENSOR_MTAP_1_V1'
}

enum DropdownTabsTypes {
  Devices = 'devices',
  Circuits = 'circuits',
  Groups = 'groups'
}

const telemetryTab: any = () => ({
  component: import('./telemetryTab/AnalyticsCardTelemetryTab.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

// const sensorsTab: any = () => ({
//   component: import('./sensorsTab/AnalyticsCardSensorsTab.vue'),
//   loading: AsyncLoading,
//   error: AsyncError,
//   delay: 0,
//   timeout: 3000
// });

const analyticsTab: any = () => ({
  component: import('./analyticsTab/AnalyticsCardAnalyticsTab.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const AnalyticsCardTypeCheckbox: any = () => ({
  component: import('./AnalyticsCardTypeCheckbox/AnalyticsCardTypeCheckbox.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    [AnalyticsTabs.Telemetry]: telemetryTab,
    // [AnalyticsTabs.Sensors]: sensorsTab,
    [AnalyticsTabs.Analytics]: analyticsTab,
    AnalyticsCardTypeCheckbox
  }
})
/**
 * @group Analytics Card
 * Analytics tab content
 */
export default class AnalyticsCard extends Vue {
  @app.State
  selectedAnalyticsTab!: AppState['selectedAnalyticsTab'];

  @app.Action(AppAction.SetSelectedAnalyticsTab)
  setSelectedAnalyticsTab!: AppActionType['SET_SELECTED_ANALYTICS_TAB'];

  @devices.State
  devices!: DevicesState['devices'];

  @devices.State
  selectedId!: DevicesState['selectedId'];

  @circuits.State
  circuits!: CircuitsState['circuits'];

  @groups.State
  groups!: GroupsState['groups'];

  @telemetry.State
  selectedReading!: TelemetryState['selectedReading'];

  @devices.Getter(DevicesGetter.GetDeviceById)
  getDeviceById!: DevicesGetterType['GET_DEVICE_BY_ID'];

  @devices.Getter(DevicesGetter.GetCircuitDevices)
  getCircuitDevices!: DevicesGetterType['GET_CIRCUIT_DEVICES'];

  @circuits.Getter(CircuitsGetter.GetCircuitById)
  getCircuitById!: CircuitsGetterType['GET_CIRCUIT_BY_ID'];

  @circuits.Getter(CircuitsGetter.GetGroupCircuits)
  getGroupCircuits!: CircuitsGetterType['GET_GROUP_CIRCUITS'];

  @groups.Getter(GroupsGetter.GetGroupById)
  getGroupById!: GroupsGetterType['GET_GROUP_BY_ID'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @telemetry.Action(TelemetryAction.SetSelectedReading)
  setSelectedReading!: TelemetryActionType['SET_SELECTED_READING'];

  @telemetry.Action(TelemetryAction.FetchDeviceTelemetry)
  fetchDeviceTelemetry!: TelemetryActionType['FETCH_DEVICE_TELEMETRY'];

  @devices.Action(DevicesAction.SetSelectedId)
  setSelectedId!: DevicesActionType['SET_SELECTED_ID'];

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  fetchID: any;

  selectedTab: AnalyticsTabsTypes = AnalyticsTabs.Telemetry;

  selectedDropdownTab: DropdownTabsTypes = DropdownTabsTypes.Devices;

  selectedDeviceId: number | null = null;

  selectedElementId: number | null = null;

  lampIsSelected: boolean = true;

  sensorIsSelected: boolean = true;

  gatewayIsSelected: boolean = true;

  get deviceTypesSelectValues(): Array<boolean> {
    return [this.lampIsSelected, this.sensorIsSelected, this.gatewayIsSelected];
  }

  get tabs() {
    if (this.selectedDropdownTab === DropdownTabsTypes.Devices) {
      return [
        {
          name: this.$t('analyticsTabs[0]'),
          value: AnalyticsTabs.Telemetry
        },
        {
          name: this.$t('analyticsTabs[2]'),
          value: AnalyticsTabs.Analytics
        },
      ] as const;
    }
    return [
      {
        name: this.$t('analyticsTabs[2]'),
        value: AnalyticsTabs.Analytics
      },
    ] as const;
  }

  get dropdownTabs() {
    return [
      {
        name: this.$tc('main.device', 2),
        value: DropdownTabsTypes.Devices
      },
      {
        name: this.$tc('main.circuit', 2),
        value: DropdownTabsTypes.Circuits
      },
      {
        name: this.$tc('main.group', 2),
        value: DropdownTabsTypes.Groups
      },
    ] as const;
  }

  get selectedDevice() {
    if (!this.selectedDeviceId) return null;
    return this.getDeviceById(this.selectedDeviceId);
  }

  get selectedElement() {
    if (!this.selectedElementId) return null;
    return this.getCircuitById(this.selectedElementId) ?? this.getGroupById(this.selectedElementId);
  }

  get selectDeviceOptions(): Option[] {
    let availableDevices: Models.Devices.Model[] = [...this.devices];
    if (this.selectedTab === AnalyticsTabs.Telemetry) {
      // stats displayed in the telemetry tab was available only for dali2 lamps atm
      // availableDevices = this.devices.filter((d) => isLamp(d) && d.parameters.powerSupply.type === 'Dali 2.0');
      // now stats should be displayed for all devices
      // availableDevices = this.devices.filter((device) => (
      //   (this.isGatewayControlCabinet(device) && this[`${device.device_type}IsSelected`])
      //   || (!isGateway(device) && this[`${device.device_type}IsSelected`])
      // ));
      availableDevices = this.devices.filter((device) => (
        (isGateway(device) && this[`${device.device_type}IsSelected`])
        || (isLamp(device) && this[`${device.device_type}IsSelected`])
      ));
    } else if (this.selectedTab === AnalyticsTabs.Analytics) {
      availableDevices = this.devices.filter((device) => (
        (isGateway(device) && this[`${device.device_type}IsSelected`])
        || (isLamp(device) && this[`${device.device_type}IsSelected`])
      ));
    } else if (this.selectedTab === AnalyticsTabs.Sensors) {
      availableDevices = this.devices.filter(isSensor);
    }
    return availableDevices.sort((a, b) => sort(a.object_id, b.object_id))
      .map((d) => ({
        text: d.object_id,
        value: d.id!
      }));
  }

  get selectCircuitOptions(): Option[] {
    let availableCircuits: Array<Models.Circuits.Model> = [...this.circuits];
    if (this.selectedTab === AnalyticsTabs.Telemetry) {
      availableCircuits = [];
    }
    return availableCircuits.sort((a, b) => sort(a.name, b.name))
      .map((e) => ({
        text: e.name,
        value: e.id!
      }));
  }

  get selectGroupOptions(): Option[] {
    let availableGroups: Array<Models.Groups.Model> = [...this.groups];
    if (this.selectedTab === AnalyticsTabs.Telemetry) {
      availableGroups = [];
    }
    return availableGroups.sort((a, b) => sort(a.name, b.name))
      .map((e) => ({
        text: e.name,
        value: e.id!
      }));
  }

  get deviceTypesOptions(): Option[] {
    return ['lamp', 'sensor', 'control-cabinet'].map((type) => ({
      text: this.$tc(`main.${type}`, 2),
      value: type === 'control-cabinet' ? 'gateway' : type,
    }));
  }

  // get circuitAndGroupOptions(): Option[] {
  //   return ['circuit', 'group'].map((type) => ({
  //     text: this.$tc(`main.${type}`, 2),
  //     value: type,
  //   }));
  // }

  get telemetryData(): Models.Telemetries.Telemetry | null {
    if (!this.selectedDevice) return null;
    // if (!this.selectedDevice || isGateway(this.selectedDevice)) return null;
    if (this.selectedReading) {
      return this.selectedReading;
    }

    return this.getLastTelemetry(this.selectedDevice.object_id, this.selectedDevice.device_type);
  }

  get telemetryControllerType(): Models.Constants.ControllerType | null {
    const controllerType: Models.Constants.ControllerType | undefined = this.telemetryData?.controller_type;
    return controllerType ?? null;
  }

  toggleSelectedType(type: string, selectValues: string): void {
    if (this.checkboxDisabled(type, selectValues)) return;
    this[`${type}IsSelected`] = !this[`${type}IsSelected`];
  }

  typeIsChecked(type: string): boolean {
    return this[`${type}IsSelected`];
  }

  isLastCheckedType(type: string, selectValues: string): boolean {
    return this[selectValues].filter((t) => t).length === 1 && this[`${type}IsSelected`];
  }

  checkboxDisabled(type: string, selectValues: string): boolean {
    return this.isLastCheckedType(type, selectValues)
      || (
        this.selectedAnalyticsTab === AnalyticsTabs.Analytics
        && type === 'sensor'
      );
  }

  isGatewayControlCabinet(device: Models.Devices.Device): boolean {
    if (isGateway(device)) {
      const gatewayTelemetry = this.getLastTelemetry(device.object_id, device.device_type);
      if (!gatewayTelemetry) return false;
      return isControlCabinetTelemetry(gatewayTelemetry);
    }
    return false;
  }

  @Watch('selectedDropdownTab')
  onSelectedDropdownTabChange() {
    if (this.selectedDropdownTab === DropdownTabsTypes.Circuits || this.selectedDropdownTab === DropdownTabsTypes.Groups) {
      this.selectedTab = AnalyticsTabs.Analytics;
      this.selectedDeviceId = null;
      this.selectedElementId = null;
    } else if (this.selectedDropdownTab === DropdownTabsTypes.Devices) {
      this.lampIsSelected = true;
      this.sensorIsSelected = false;
      this.gatewayIsSelected = true;
      this.selectedElementId = null;
    }
  }

  @Watch('selectedTab')
  onSelectedTabChange(newValue, oldValue) {
    if (this.selectedTab === AnalyticsTabs.Telemetry) {
      this.lampIsSelected = true;
      this.sensorIsSelected = true;
      this.gatewayIsSelected = true;
      this.setSelectedAnalyticsTab(AnalyticsTabs.Telemetry);
    } else if (this.selectedTab === AnalyticsTabs.Analytics) {
      if (this.fetchID) {
        clearInterval(this.fetchID);
      }
      this.lampIsSelected = true;
      this.sensorIsSelected = false;
      this.gatewayIsSelected = true;
      this.setSelectedAnalyticsTab(AnalyticsTabs.Analytics);
    }

    if ((newValue === AnalyticsTabs.Telemetry && oldValue === AnalyticsTabs.Analytics) || this.selectedTab === 'telemetry') {
      this.fetchID = setInterval(() => this.fetchDeviceTelemetry({ objectId: this.selectedDevice!.object_id }), 30000);
    }
  }

  @Watch('selectedDeviceId')
  onSelectedDeviceIdChange(newValue, oldValue) {
    if (newValue) this.selectedElementId = null;
    if (oldValue !== null && this.selectedReading) {
      this.setSelectedReading({ reading: null });
    }
    if (this.selectedDeviceId) {
      const paramsDevice = this.selectedDevice?.parameters.device;
      if (hasCoordinates(paramsDevice)) {
        if (paramsDevice?.latitude && paramsDevice?.longitude) {
          this.setSelectedMarkerId(this.selectedDeviceId);
        }
      }

      if (this.selectedTab === 'telemetry') {
        if (this.fetchID) {
          clearInterval(this.fetchID);
        }

        this.fetchID = setInterval(() => this.fetchDeviceTelemetry({ objectId: this.selectedDevice!.object_id }), 30000);
      }
    }
  }

  @Watch('selectedElementId')
  onSelectedElementIdChange(newValue) {
    if (newValue) this.selectedDeviceId = null;
  }

  @Watch('selectDeviceOptions')
  onSelectDeviceOptionsChange() {
    if (!this[`${this.selectedDevice?.device_type}IsSelected`]) this.selectedDeviceId = null;
  }

  mounted() {
    this.setSelectedAnalyticsTab(AnalyticsTabs.Telemetry);
    this.selectedDeviceId = this.selectedId;
  }

  beforeDestroy() {
    this.setSelectedId(null);
    this.setSelectedMarkerId(null);
    this.setSelectedReading({ reading: null });
    this.setSelectedAnalyticsTab(AnalyticsTabs.None);
    if (this.fetchID) {
      clearInterval(this.fetchID);
    }
  }
}
